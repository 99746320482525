import './portfolio.css'
import Projects from './Projects'
import ProjectsCategories from './ProjectsCategories'
import data from './data'
import { useState } from 'react'

const Portfolio = () => {
  const [projects, setProjects] = useState(data);

  const categories = data.map(item => item.category);
  const uniqueCategories = ["all", ...new Set(categories)];
  
  const filterProjectsHandler = (category) => {
    if(category === "all") {
      setProjects(data);
      return;
    }

    const filterProjects = data.filter(project => project.category === category);
    setProjects(filterProjects);
  }
  

  return (
    <section id="portfolio">
      <h2>Projects</h2>
      <p>
      These are mostly projects from school at the University of Central Oklahoma. Unfortunately, a lot of the projects I have worked on cannot be disclosed due to Security Classification or Non-Disclosure Agreements. Use the buttons to toggle the different categories.
      </p>
      <div className="container portfolio__container" data-aos="fade-up">
        <ProjectsCategories categories={uniqueCategories} onFilterProjects={filterProjectsHandler}/>
        <Projects projects={projects}/>
      </div>
    </section>
  )
}

export default Portfolio